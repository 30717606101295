/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/hyperacusis/'], ['en', 'https://www.hear.com/hearing-loss/hyperacusis/'], ['en-US', 'https://www.hear.com/hearing-loss/hyperacusis/'], ['en-CA', 'https://ca.hear.com/hearing-loss/hyperacusis/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-hyperacusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-hyperacusis",
    "aria-label": "what is hyperacusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is hyperacusis?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Definition"), ": hyperacusis is the medical term for the inability to tolerate ordinary noises, to the extent that this intolerance causes distress and difficulties in a patient’s everyday life. The precise degree of noise sensitivity varies from patient to patient, and the causes vary as well."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms",
    "aria-label": "symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This condition is an auditory problem in which an effected person experiences an ", React.createElement(_components.strong, null, "extremely heightened sensitivity to everyday sounds"), ". Generally, by the time an individual seeks treatment, this sensitivity will have progressed to the stage where it is ", React.createElement(_components.strong, null, "painful when noise reaches a certain level or pitch"), ". Usually, this will have a ", React.createElement(_components.strong, null, "significant impact on the quality of their lives"), ", making it difficult to carry out daily activities and socialize in situations where ambient noise reaches their threshold."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At first, symptoms could begin with a sense of discomfort, which often begins in a single ear before progressing to affect both ears. It may also emerge from tinnitus as a progression of that condition. However, not all cases of tinnitus require hyperacusis treatment. As their condition worsens, people with hyperacusis may feel like ordinary situations, such as workplaces or streets, are becoming harder to tolerate due to the noise generated by everything around them. They may, therefore, seek to avoid those situations, covering their ears or removing themselves from physical locations where noise levels are too much for them. People with hyperacusis regularly experience depression and anger because of their noise intolerance."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They may also show symptoms of anxiety, such as mood swings, a heightened pulse rate, and perspiration. When the condition worsens, there is a more physical discomfort when noise levels reach a certain level, further driving them into quiet, isolated situations. This means that many people avoid social events and do not seek treatment at an early stage, which is not good for their overall well-being."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes",
    "aria-label": "causes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Currently, ", React.createElement(_components.strong, null, "there is no medical consensus on what exactly causes hyperacusis"), ". We do know that it is associated with tinnitus. In fact, around 50 percent of people living with tinnitus also go on to develop sensitivity to noise. But many people do not have tinnitus at any stage. Others have linked cases of the condition to autism and birth defects, which may be the cause in a small number of individuals. However, auditory experts generally caution against ascribing a single cause. They remind us that the auditory nerve connects to the central nervous system in extremely complex ways, and that there could be many roots of noise sensitivity. For instance, it could also be due to the side effects of an auto-immune disorder, ear infections, and ", React.createElement(_components.a, {
    href: "/useful-knowledge/blast-injury/",
    className: "c-md-a"
  }, "traumatic acoustic events"), " like the sound of a gun blast."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, medical data suggests that the ", React.createElement(_components.strong, null, "most common primary cause is physical trauma"), ". Any shock to the nervous system could lead to the condition, such as whiplash in an automobile accident or even a sports injury. The problem for medical therapists is that it is not known to be caused by a pathogen or a treatable agent."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There is no obvious surgical remedy for such a complex and poorly understood nervous ailment. What we do know is that ", React.createElement(_components.strong, null, "around 1 in 50,000 Americans report experiencing it"), " – numbers that are probably understated somewhat due to people delaying medical assistance. Hyperacusis is a serious auditory problem and, though a cure may not be available, there are therapeutic options that can alleviate its symptoms."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-options",
    "aria-label": "treatment options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment options"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Almost anyone who is diagnosed with hyperacusis will be presented with a range of treatment options. Although there is no cure, it can be effectively managed, which is what most of these treatment methods seek to achieve."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One popular choice is ", React.createElement(_components.strong, null, "cognitive behavioral therapy (CBT)"), ". CBT therapists seek to engage patients directly with how they experience pain and noise, helping to avoid stressful situations, and creating a positive mindset to handle the symptoms. In other cases, patients may go through sound therapy. In this case, medical experts may seek to desensitize a patient’s ears over a period of months. By using “noise generators” in specially controlled environments, these experts can slowly alter the way patients perceive outside noise. This helps to reduce the volume of the sounds they hear. The noise generators create what is known as “pink sound,” which has been found to have therapeutic properties for many patients. However, these sessions can be difficult, sometimes extending for up to eight hours and requiring multiple appointments every month."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Partly for those reasons, the best therapy in some cases may be to deal with the distress and depression that the condition can cause. ", React.createElement(_components.strong, null, "Relaxation therapies and counseling"), " can help here. In most cases, patients are also asked to take responsibility for changing their lifestyle to avoid isolation, which may involve exposure to painful noises. The goal is that they get used to such sensations and avoid the effects of isolation."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
